<template>
  <div v-if="isLoaded">
    <FormTemplateFilter
      :search.sync="filter.name"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Add Form Template"
        to-path="/form-template/add"
        @change="fetchFilter()" />
      <b-table
        :items="items"
        :fields="fields"
        striped
        hover
        responsive
        @sort-changed="sortFilter($event)"
        @row-clicked="rowClick($event)">
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import TableTop from '@/components/TableTop.vue'
import FormTemplateFilter from '@/components/New/FormTemplate/FormTemplateFilter.vue'
import FormTemplateProvider from '@/resources/FormTemplateProvider'

const FormTemplateService = new FormTemplateProvider()

export default {
  components: {
    TableTop,
    FormTemplateFilter
  },
  data () {
    return {
      isLoaded: false,
      filter: {
        name: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 0
      },
      fields: [
        { key: 'name', label: 'Form Template Name' }
      ],
      items: []
    }
  },
  async created () {
    try {
      await this.getFormTemplate()
    } finally {
      this.isLoaded = true
    }
  },
  methods: {
    fetchFilter () {
      this.setPagination(1)
      this.getFormTemplate()
    },
    async getFormTemplate () {
      try {
        const page = this.$route?.query?.page || this.pagination?.page || 1
        const limit = this.$route?.query?.limit || this.pagination?.limit || 10
        const data = await FormTemplateService.paginate(
          page, limit,
          this.filter
        )
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
        console.log('this.items', this.items)
        console.log('data', data)
      } catch (error) {
        console.log('error', error)
      }
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getFormTemplate()
    },
    setPagination (page, totalDocs) {
      this.$router.replace(`form-template?page=${page}`)
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs || this.pagination.totalDocs
    },
    rowClick (val) {
      this.$router.push({ name: 'form-template-detail', params: { id: val.id } })
    },
    sortFilter (ctx) {
      console.log(ctx)
    }
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0;
}
</style>
